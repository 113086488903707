import React from 'react';
import { PageHeaderComposition } from '@csv/styleguide/src/features/page-header/page-header';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '@csv/styleguide/src/elements/container/container';
import { PageBody } from '../features/content-page/content-page';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

const SchooltoernooiPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Schooltoernooi"
        description="Informatie over het jaarlijkse schooltoernooi bij CSV Handbal Castricum."
      />
      <PageHeaderComposition
        title="Schooltoernooi"
        byline="Kom handballen bij CSV!"
      />
      <Container>
        <PageBody>
          <p>
            Zondag 30 juni 2024 vindt weer het jaarlijkse
            CSV-Schoolhandbaltoernooi plaats: een gezellige afsluiter van het
            schooljaar! Dit toernooi is weer mede mogelijk gemaakt door De
            Triangel Groep. Dit jaar wordt het CSV-Schoolhandbaltoernooi
            georganiseerd voor de basisschoolgroepen groepen 3, 4,5 en 6 uit
            Castricum en omstreken. Het toernooi is van 09.00 tot +/-16.00 uur
            (afhankelijk van de aanmeldingen per poule) en vindt plaats op
            Sportpark Wouterland in Castricum.
          </p>
          <p>
            Een team van Groep 3, 4 en 5 heeft minimaal 5 spelers nodig; Een
            team van Groep 6 heeft minimaal 7 spelers nodig.
          </p>
          <p>
            Hieronder de links naar de flyer, die ook uitgedeeld is op de
            scholen. En natuurlijk het allerbelangrijkste: het
            inschrijfformulier. De oefendag is dit jaar op zaterdag 15 juni van
            10 tot 12 uur, ook op sportpark Wouterland! Wij hopen jullie
            allemaal te zien voor een sportieve en mooie dag.
          </p>
          <a
            href="https://forms.gle/wzbTEWK7T2bx99Sh9"
            target="_blank"
            rel="noopener noreferrer"
          >
            Inschrijfformulier
          </a>
          {data.file &&
            data.file.childImageSharp &&
            data.file.childImageSharp.fluid && (
              <Img fluid={data.file.childImageSharp.fluid} />
            )}
        </PageBody>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query SchoolhandbalQuery {
    file(relativePath: { eq: "schoolhandbal.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default SchooltoernooiPage;
