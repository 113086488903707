/* eslint-disable react/display-name */
import Container from '@csv/styleguide/src/elements/container/container';
import Heading from '@csv/styleguide/src/elements/heading/heading';
import { PageHeaderComposition } from '@csv/styleguide/src/features/page-header/page-header';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import React from 'react';
import styled from 'styled-components';
import PortableText from '../../components/portable-text';
import { SanityPage } from '../../generated/graphql-types';
import Img, { FluidObject } from 'gatsby-image';

type PageProps = Pick<
  SanityPage,
  '_rawBody' | 'title' | 'publishedAt' | 'mainImage'
>;

export const PageBody = styled.div`
  max-width: 700px;
  padding: 0 8px;
  /* margin: 0 auto; */

  @supports (max-width: 80ch) {
    max-width: 80ch;
  }

  ${Heading} {
    margin-top: 1.5em;
  }
`;

const PageGrid = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 0;
  padding-top: 0;

  @media screen and (min-width: 764px) {
    grid-template-columns: 2fr 1fr;
  }

  aside {
    order: 2;
    display: none;

    @media screen and (min-width: 764px) {
      display: block;
    }
  }
`;

const PageImage = styled.figure`
  max-width: 760px;
  /* max-height: 600px; */
  display: block;
  margin: -32px 0 2em;
  border-radius: 4px;
  overflow: hidden;
  border: 8px solid white;
`;

const ToC = styled.nav`
  position: sticky;
  top: 0;
  padding: 1rem;

  ul {
    padding-left: 1rem;

    a {
      color: #2041a3;

      &:visited {
        color: rgb(85, 26, 139); //webkit default
      }
    }

    ul {
      li a {
        font-size: 0.85rem;
      }
    }
  }
`;

function nestHeadings(headings) {
  const res = [];
  let currentAncestorIndex = 0;
  let currentAncestorLevel: number;
  let isNesting = false;

  for (let i = 0; i < headings.length; i++) {
    const currElem = headings[i];

    if (
      res.length === 0 ||
      (currentAncestorLevel && currElem.level === currentAncestorLevel)
    ) {
      // push if first element or if same level as previous item
      currentAncestorLevel = currElem.level;
      currentAncestorIndex = i;
      isNesting = false;

      res.push(currElem);
    } else if (currElem.level > currentAncestorLevel && !isNesting) {
      isNesting = true;

      const start = headings.slice(currentAncestorIndex + 1);
      const end = start.findIndex(j => j.level === currentAncestorLevel);

      const slice = end > 0 ? start.slice(0, end) : start.slice(0);

      res[res.length - 1].children = nestHeadings(slice);
    }
  }

  return res;
}

function getTOC(rawBody) {
  const headings = ['h1', 'h2', 'h3', 'h4', 'h6', 'h5'];
  const docHeadings = rawBody
    ?.filter(elem => elem._type === 'block')
    .filter(elem => headings.includes(elem.style))
    .map(heading => ({
      level: parseInt(heading.style.charAt(1), 10),
      text: heading.children.map(c => c.text).join(''),
      key: heading._key,
      children: [],
    }));

  return nestHeadings(docHeadings);
}

export default function({
  title,
  publishedAt,
  _rawBody,
  mainImage,
}: PageProps) {
  const tableOfContents = getTOC(_rawBody);

  const renderList = headings => {
    return headings?.length > 0 ? (
      <ul>
        {headings.map(item => (
          <li key={`toc-${item.key}`}>
            <a href={`#${item.key}`}>{item.text}</a>
            {renderList(item?.children)}
          </li>
        ))}
      </ul>
    ) : null;
  };
  return (
    <>
      <PageHeaderComposition
        subTitle="Informatie"
        title={title || ''}
        disableVerticalMargin
      />
      <PageGrid>
        <aside>
          <ToC>
            <Heading variant="h6" as="h2">
              PAGINA-INHOUD
            </Heading>
            {renderList(tableOfContents)}
          </ToC>
        </aside>
        <div>
          {mainImage?.image?.asset?.fluid && (
            <PageImage>
              <Img
                // Typecasting is needed here, see: https://github.com/sanity-io/gatsby-source-sanity/issues/57
                fluid={mainImage.image.asset.fluid as FluidObject}
                alt={mainImage.alt as string}
              />
            </PageImage>
          )}
          {_rawBody && (
            <PageBody>
              <PortableText blocks={_rawBody} />
            </PageBody>
          )}
        </div>
      </PageGrid>
    </>
  );
}
