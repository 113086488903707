/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import getYouTubeId from 'get-youtube-id';
import YouTube from 'react-youtube';

import clientConfig from '../../client-config';
import BasePortableText from '@sanity/block-content-to-react';
import Heading from '@csv/styleguide/src/elements/heading/heading';

import { getFluidGatsbyImage } from 'gatsby-source-sanity';

const BlockRenderer = props => {
  const { style = 'normal', _key } = props.node;

  if (/^h\d/.test(style)) {
    // match h1-6
    // TODO disallow h1 in sanity?

    return (
      <Heading as={style} variant={style} hasMargin id={_key}>
        {props.children}
      </Heading>
    );
  }

  if (style === 'blockquote') {
    return <blockquote>- {props.children}</blockquote>;
  }

  // Fall back to default handling
  return BasePortableText.defaultSerializers.types.block(props);
};

const ImageRenderer = props => {
  const {
    asset: { _id: assetId },
  } = props.node;

  const fluidProps = getFluidGatsbyImage(
    assetId,
    { maxWidth: 630 },
    clientConfig.sanity,
  );

  return (
    <>
      <Img
        // Typecasting is needed here, see: https://github.com/sanity-io/gatsby-source-sanity/issues/57
        fluid={fluidProps as FluidObject}
      />
    </>
  );
};

const YouTubeRenderer = ({ node }) => {
  const { url } = node;
  const id = getYouTubeId(url);
  return <YouTube videoId={id} />;
};

const FileRenderer = ({ node }) => {
  return (
    <a href={node.asset.url} download>
      {node.asset.originalFilename}
    </a>
  );
};

const ExternalLinkRenderer = ({ children, mark }) => {
  return mark.blank ? (
    <a href={mark.href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <a href={mark.href}>{children}</a>
  );
};

const contentTypeMapping = {
  page: '/over-csv/',
  post: '/nieuws/',
  event: '/agenda/',
};

const InternalLinkRenderer = ({ children, mark }) => {
  return (
    <a
      href={`${contentTypeMapping[mark.item._type]}${mark.item.slug.current}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

const serializers = {
  types: {
    block: BlockRenderer,
    image: ImageRenderer,
    youtube: YouTubeRenderer,
    file: FileRenderer,
  },
  marks: {
    link: ExternalLinkRenderer,
    internalLink: InternalLinkRenderer,
  },
};

const PortableText = ({ blocks }: any) => (
  <BasePortableText
    blocks={blocks}
    {...clientConfig.sanity}
    serializers={serializers}
  />
);

export default PortableText;
